<template>
    <div class="layout-config h-100 p-10">
      <Row type="flex" :gutter="10">
        <Col span="4">
          <Input v-model="param.query" placeholder="请输入研学资讯标题" clearable />
        </Col>
        <Col span="4">
          <Button type="primary" @click="getData()">查询</Button>
          <Button type="success" class="m-l-5" to="/config/dataManage/researchNews/researchNewsPage">新增</Button>
        </Col>
      </Row>
      <Table border :columns="cols" :data="table.data" ref="table" class="m-t-10" />
      <Page :page="param.current" @on-change="getData" :page-size="10" :total="table.total" show-total class="text-r m-t-10" />
    </div>
  </template>
  
  <script>
  import { getNews, deleteNews } from '@/libs/api/researchNews'
  import { util } from '@/libs'
  export default {
    data() {
      const _this = this
      return {
        param: {
            current: 1,
            size: 10,
            order: 'newTitle',
        },
        table: {},
        cols: [
          { title: "序号", type: "index", key: "index", width: 70, align: "center" },
          { title: "研学资讯标题", key: "newTitle" },
          {
              title: '资讯内容',
              key: 'newContent',
              ellipsis: true,
              render(h, w) {
                  return h('Tooltip', {
                      props: {
                          content: util.getSimpleText(w.row.newContent),
                          trigger: 'hover',
                          size: 'small',
                          transfer: true,
                          placement: 'bottom-start',
                          maxWidth: 400
                      }
                  }, [h('span', { class: 'ivu-table-cell-ellipsis', domProps: { innerHTML: util.getSimpleText(w.row.newContent) } })])
              }
          },
          { title: "创建人", key: "newEdit" },
          { title: "所属机构", key: "newOrg" },
          { title: "创建时间", key: "newDate" },
          {
            title: "操作", width: 200, render(h, w) {
              return [
                h("Button", {
                  props: { type: "info", size: "small" },
                  on: {
                    click() {
                      _this.$router.push({
                        path: '/config/dataManage/researchNews/researchNewsView',
                        query: { id: w.row.researchNewId }
                      })
                    }
                  }
                }, "查看"),
                h("Button", {
                  props: { type: "success", size: "small" },
                  on: {
                    click() {
                      _this.$router.push({
                        path: '/config/dataManage/researchNews/researchNewsPage',
                        query: { id: w.row.researchNewId }
                      })
                    }
                  }
                }, "编辑"),
                h("Button", {
                  props: { type: "error", size: "small" },
                  on: {
                    click() {
                      _this.$Modal.confirm({
                        title: "删除",
                        content: `是否删除当前${w.row.newTitle}?`,
                        onOk: () => {
                          deleteNews({ new_id: w.row.researchNewId }).then(() => {
                            _this.$Message.success("删除成功！");
                            _this.getData();
                          });
                        }
                      });
                    }
                  }
                }, "删除"),
              ]
            }
          }
        ]
      }
    },
    methods: {
      // 获取数据列表
      getData(page) {
        Object.assign(this.param, { current: page ? page : 1 })
        getNews(this.param).then(res => {
            res.code == "HA0200" ? this.table = res : this.$Message.error(res.msg)
        });
      }
    },
    mounted() {
      this.getData()
    }
  }
  </script>
  
  <style lang="less" scoped></style>
  